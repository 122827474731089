<template>
  <page-header-wrapper >
    <span slot="content">
      用户ID： {{ this.queryParam.playerId }} <a-divider type="vertical" />当前PASS： {{ currentPass }} <a-divider type="vertical" />
      <a-dropdown>
        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
          修改PASS <a-icon type="down" />
        </a>
        <a-menu slot="overlay">
          <a-menu-item>
            <a @click="unsetPass">无PASS</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="setPass(4)">GOLD PASS</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="setPass(6)">FREEDOM PASS</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <a-divider type="vertical" />
      设置PASS需要保证下面有一张同等级卡片
    </span>
    <a-card :bordered="false">

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.cardId + record.cardTypeId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="你确定要删除吗?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleDelete(record)"
            >
              <a href="#">删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>

      <modal-form
        :title="formTitle"
        :visible="formVisible"
        :loading="confirmLoading"
        @cancel="handleCancel"
        @ok="handleSave"
      >
        <player-card-form
          ref="cardForm"
          :model="formModel"
        />
      </modal-form>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import {
  deletePlayerCard,
  getPlayerCardCurrent,
  getPlayerCardList,
  savePlayerCard, setPlayerCard,
  unsetPlayerCard
} from '@/api/game/mai2'
import PlayerCardForm from '@/views/game/mai2/module/PlayerCardForm'
import { dbDate } from '@/utils/util'
import ModalForm from '@/components/ModalForm/ModalForm'
import { Mai2DxPassType } from '@/model/mai2const'

export default {
  name: 'PlayerCardList',
  components: {
    PlayerCardForm,
    STable,
    ModalForm
  },
  data () {
    return {
      formTitle: '',
      formVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {
        playerId: this.$route.params.playerId
      },
      loadData: parameter => {
        return getPlayerCardList(Object.assign(parameter, this.queryParam))
      },
      currentPass: 'LOADING',
      columns: [
        {
          title: '卡片ID',
          dataIndex: 'cardId',
          sorter: true
        },
        {
          title: '卡片类型',
          dataIndex: 'cardTypeId',
          sorter: true,
          customRender: (record) => {
            return Mai2DxPassType[record].label
          }
        },
        {
          title: '角色ID',
          dataIndex: 'charaId',
          sorter: true
        },
        {
          title: '地图ID',
          dataIndex: 'mapId',
          sorter: true
        },
        {
          title: '开始时间',
          dataIndex: 'startDate',
          sorter: true
        },
        {
          title: '结束时间',
          dataIndex: 'endDate',
          sorter: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  mounted () {
    this.loadPassStatus()
  },
  methods: {
    loadPassStatus () {
      getPlayerCardCurrent(this.queryParam).then(res => {
        if (res && res.length > 0) {
          this.currentPass = Mai2DxPassType[res[0]].label + ' PASS'
        } else {
          this.currentPass = 'NO PASS'
        }
      })
    },
    handleAdd () {
      this.formTitle = '添加卡片'
      this.formModel = null
      this.formVisible = true
      // Wait the form being created
      this.$nextTick(() => {
        this.$refs.cardForm.form.resetFields()
      })
    },
    handleCancel () {
      this.formVisible = false
    },
    handleEdit (record) {
      this.formTitle = '修改卡片'
      this.formVisible = true
      this.$nextTick(() => {
        this.formModel = record
      })
    },
    handleSave () {
      const form = this.$refs.cardForm.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.startDate = dbDate(values.startDate)
          values.endDate = dbDate(values.endDate)
          savePlayerCard(this.queryParam, values).then(() => {
            this.formVisible = false
            form.resetFields()
            this.$refs.table.refresh()
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleDelete (record) {
      deletePlayerCard({ playerId: this.queryParam.playerId, cardId: record.cardId, cardTypeId: record.cardTypeId }).then(() => {
        this.$message.success('删除成功')
        this.$refs.table.refresh()
      })
    },
    setPass (type) {
      console.log(this.$refs.table)

      setPlayerCard({ playerId: this.queryParam.playerId, type }).then(() => {
        this.$message.success('设置成功')
        this.loadPassStatus()
      })
    },
    unsetPass () {
      unsetPlayerCard({ playerId: this.queryParam.playerId }).then(() => {
        this.$message.success('设置成功')
        this.loadPassStatus()
      })
    }
  }
}
</script>

<style scoped>

</style>
