import { render, staticRenderFns } from "./PlayerCardList.vue?vue&type=template&id=af73d106&scoped=true&"
import script from "./PlayerCardList.vue?vue&type=script&lang=js&"
export * from "./PlayerCardList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af73d106",
  null
  
)

export default component.exports